import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { usePermissionStore } from '@/stores'
import http from '@/plugins/http'
import httpErrorHandler from '@/plugins/httpErrorHandler'
import { TagSource } from '@/interface/CompanyTagType'

interface Auth {
  IdToken: string | null
  AccessToken: string | null
  RefreshToken: string | null
  Sub: string | null
  Expired: boolean
}

interface CurrentCompany {
  id: number | null
  companyName: string | null
  contractStartTime: string | null
  contractEndTime: string | null
  memberCount: number
  extraMemberCount: number
  isExtension: boolean | null
  planName: string | null
}

interface Position {
  id: number
  name: string
}

interface Tags {
  id: number
  name: string
  official_type: string
  source: TagSource
}

interface Permissions {
  id: string
  name: string
  show_name: string
}

interface Plans {
  created_at: string
  guard_name: string
  id: number
  is_assignable: number
  module_id: number
  name: string
  service_id: number
  show_name: string
  updated_at: string
}

interface User {
  id: number | null
  name: string | null
  email: string | null
  userType: string | null
  roleName: string | null
  permissions: Permissions[] | []
  plans: Plans[] | []
  position: Position
  planName: string | null
  companyAmount: number
  tags: Tags[] | []
}

export type Users = {
  auth: Auth
  currentCompany: CurrentCompany
  user: User
  platform: string
}

export const useUserStore = defineStore('GOODLINKER_COMPANY', {
  state: (): Users => ({
    auth: {
      IdToken: null,
      AccessToken: null,
      RefreshToken: null,
      Sub: null,
      Expired: false
    },
    currentCompany: {
      id: null,
      companyName: null,
      contractStartTime: null,
      contractEndTime: null,
      memberCount: 0,
      extraMemberCount: 0,
      isExtension: null,
      planName: null
    },
    user: {
      id: null,
      name: null,
      email: null,
      userType: null,
      roleName: null,
      planName: null,
      companyAmount: 0,
      permissions: [],
      plans: [],
      position: {
        id: 0,
        name: ''
      },
      tags: []
    },
    platform: 'goodlinker'
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'GOODLINKER_COMPANY',
        storage: localStorage
      }
    ]
  },
  actions: {
    async getCompanyUser () {
      const companyId = this.currentCompany.id
      try {
        const res = await http.get(`/common/v3/companies/${companyId}`)
        const resCompanyId = res.data.data[0].company_id
        if (resCompanyId === companyId) {
          this.currentCompany.contractStartTime = res.data.data[0].contract_start_time
          this.currentCompany.contractEndTime = res.data.data[0].contract_end_time
          this.currentCompany.memberCount = res.data.data[0].member_limit
          this.currentCompany.extraMemberCount = res.data.data[0].extra_buy_member
          this.currentCompany.isExtension = res.data.data[0].is_extension === 1
          this.currentCompany.planName = res.data.data[0].plan_name
        } else {
          Sentry.captureMessage('公司 ID 不相符', {
            level: 'error',
            tags: {
              action: 'getCompanyUser'
            },
            extra: {
              expectedCompanyId: companyId,
              actualCompanyId: resCompanyId
            }
          })
        }
      } catch (error) {
        httpErrorHandler(error)
      }
    },
    async getCompanyUserMe () {
      const companyId = this.currentCompany.id
      const permissionStore = usePermissionStore()
      try {
        const res = await http.get(`/common/v3/companies/${companyId}/users/me`)
        this.user.id = res.data.data.user_id
        this.user.name = res.data.data.user_name
        this.user.email = res.data.data.user_email
        this.user.userType = res.data.data.usertype
        this.user.roleName = res.data.data.role?.name
        this.user.permissions = res.data.data.permissions
        this.user.plans = res.data.data.plan_permissions
        this.user.position = res.data.data.position
        permissionStore.permissions = res.data.data.permissions
        permissionStore.plans = res.data.data.plan_permissions
        if (this.user.roleName) {
          this.user.planName = this.user.roleName.split(' ')[1]
        }
        if (this.user.userType) {
          permissionStore.setSelfPermissions(this.user.userType)
        }
        Sentry.setUser({
          company_id: this.currentCompany.id,
          company_name: this.currentCompany.companyName,
          id: this.user.id || '',
          username: this.user.name || '',
          email: this.user.email || ''
        })
      } catch (error) {
        httpErrorHandler(error)
      }
    },
    async getAuthSub () {
      const authSub = this.auth.Sub
      if (!authSub) {
        throw new Error('Auth Sub 為 null')
      }
      return authSub
    },
    async getCompanyId () {
      const companyId = this.currentCompany.id
      if (companyId === null) {
        throw new Error('公司 ID 為 null')
      }
      return companyId
    },
    async getUserId () {
      const userId = this.user.id
      if (userId === null) {
        throw new Error('使用者 ID 為 null')
      }
      return userId
    }
  }
})

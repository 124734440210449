import { useWebsocketStore } from '@/stores'

const Layout = () => import('@/views/Layout.vue')
const Home = () => import('@/views/Home.vue')
const Edit = () => import('@/views/Home/Edit.vue')
const Dashboard = () => import('@/views/Dashboard.vue')
// const History = () => import('@/views/History.vue')
const ChartDashboard = () => import('@/views/ChartDashboard.vue')
// const AnalysisChart = () => import('@/views/AnalysisChart.vue')
const DataCenter = () => import('@/views/DataCenter.vue')
const EventAlbums = () => import('@/views/EventAlbums.vue')
const Device = () => import('@/views/Device.vue')
const LesiesStatus = () => import('@/views/Device/LesiesStatus.vue')
const Alert = () => import('@/views/Alert.vue')
const ExcelList = () => import('@/views/Excel/ExcelList.vue')
const ExcelTemplate = () => import('@/views/Excel/ExcelTemplate.vue')
const ExcelSetting = () => import('@/views/Excel/ExcelSetting.vue')
const AuthLog = () => import('@/views/Setting/AuthLog.vue')
const InnerMenuTemplate = () => import('@/views/Template/InnerMenu.vue')
const CompanyTag = () => import('@/views/Setting/CompanyTag.vue')
const CompanyTagAll = () => import('@/views/Setting/CompanyTagAll.vue')
// const CompanyDescription = () => import('@/views/Setting/CompanyDescription.vue')
const Member = () => import('@/views/Setting/Member.vue')
// const Permission = () => import('@/views/Setting/Permission.vue')
const Log = () => import('@/views/Setting/Log.vue')
const Plan = () => import('@/views/Plan.vue')

const viewRouter = [
  {
    path: '/',
    component: Layout,
    redirect: { path: '/dashboard' },
    children: [
      {
        name: 'Home',
        meta: {
          head: '自訂首頁 - WarRoom - 谷林運算 GoodLinker',
          title: 'home',
          // free 和 basic 無權限使用，但有一個 CTA 按鈕讓他們升級
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'kanban'
        },
        path: '/home',
        component: Home,
        hidden: false
      // beforeEnter: () => {
      //   const webSocketStore = useWebsocketStore()
      //   webSocketStore.connect()
      // }
      }, {
        name: 'Edit',
        meta: {
          head: '編輯自訂首頁 - WarRoom - 谷林運算 GoodLinker',
          title: 'Edit',
          planName: ['premium']
        },
        path: '/home/edit/:id',
        component: Edit,
        hidden: true,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'Dashboard',
        meta: {
          head: '儀表板 - WarRoom - 谷林運算 GoodLinker',
          title: 'dashboard',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'kanban'
        },
        path: '/dashboard',
        component: Dashboard,
        hidden: false
      }, 
      // {
      //   name: 'History',
      //   meta: {
      //     head: '歷史圖表 - WarRoom - 谷林運算 GoodLinker',
      //     title: 'history',
      //     planName: [ 'free', 'basic', 'premium' ],
      //     groupName: 'data'
      //   },
      //   path: '/history',
      //   component: History,
      //   hidden: false,
      //   beforeEnter: () => {
      //     const websocketStore = useWebsocketStore()
      //     websocketStore.disconnect()
      //   }
      // },
      {
        name: 'ChartDashboard',
        meta: {
          head: '通用圖表 - WarRoom - 谷林運算 GoodLinker',
          title: 'chartDashboard',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'reports'
        },
        path: '/chart-dashboard',
        component: ChartDashboard,
        new: true,
        hidden: false,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, 
      // {
      //   name: 'AnalysisChart',
      //   meta: {
      //     head: '分析報表 - WarRoom - 谷林運算 GoodLinker',
      //     title: 'analysisChart',
      //     planName: [ 'free', 'basic', 'premium' ],
      //     groupName: 'reports'
      //   },
      //   path: '/analysis-chart',
      //   component: AnalysisChart,
      //   hidden: false,
      //   beforeEnter: () => {
      //     const websocketStore = useWebsocketStore()
      //     websocketStore.disconnect()
      //   }
      // },
      {
        name: 'DataCenter',
        meta: {
          head: '數據中心 - WarRoom - 谷林運算 GoodLinker',
          title: 'dataCenter',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'data'
        },
        path: '/data-center',
        component: DataCenter,
        hidden: false,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'EventAlbums',
        meta: {
          head: '事件簿 - WarRoom - 谷林運算 GoodLinker',
          title: 'eventAlbums',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'data'
        },
        path: '/event-albums',
        component: EventAlbums,
        hidden: false,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'Alert',
        meta: {
          head: '警報分析 - WarRoom - 谷林運算 GoodLinker',
          title: 'alert',
          // free 和 basic 無權限使用
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'reports'
        },
        path: '/alert',
        component: Alert,
        hidden: false,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, 
      {
        name: 'excelList',
        meta: {
          head: 'Excel - WarRoom - 谷林運算 GoodLinker',
          title: 'excelList',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'reports'
        },
        path: '/excel',
        component: ExcelList,
        hidden: false,
        new: true,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'excelTemplate',
        meta: {
          head: 'Excel - WarRoom - 谷林運算 GoodLinker',
          title: 'excelTemplate',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'reports'
        },
        path: '/excel-template/:companyId/:id',
        component: ExcelTemplate,
        hidden: true,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'excelSetting',
        meta: {
          head: 'Excel - WarRoom - 谷林運算 GoodLinker',
          title: 'excelSetting',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'reports'
        },
        path: '/excel-setting/:companyId/:id',
        component: ExcelSetting,
        hidden: true,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, 
      {
        name: 'Device',
        meta: {
          head: '裝置資訊 - WarRoom - 谷林運算 GoodLinker',
          title: 'device',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'settings'
        },
        path: '/device',
        component: Device,
        hidden: false,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'LesiesStatus',
        meta: {
          head: 'LESI 斷線紀錄 - WarRoom - 谷林運算 GoodLinker',
          title: 'Lesies Status',
          planName: [ 'free', 'basic', 'premium' ]
        },
        path: '/device/:id/status',
        component: LesiesStatus,
        hidden: true,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'Company',
        meta: {
          head: '公司資訊- WarRoom - 谷林運算 GoodLinker',
          title: 'company',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'settings'
        },
        path: '/setting/member',
        component: InnerMenuTemplate,
        hidden: false,
        children: [
          {
            name: 'Member',
            meta: {
              head: '公司資訊- WarRoom - 谷林運算 GoodLinker',
              title: 'member',
              planName: [ 'free', 'basic', 'premium' ]
            },
            path: '/setting/member',
            component: Member,
            hidden: true,
            beforeEnter: () => {
              const websocketStore = useWebsocketStore()
              websocketStore.disconnect()
            }
          }, {
            name: 'CompanyTag',
            meta: {
              title: 'companyTag',
              planName: [ 'free', 'basic', 'premium' ],
              groupName: 'settings'
            },
            path: '/setting/companyTag',
            component: CompanyTag,
            hidden: false,
            beforeEnter: () => {
              const websocketStore = useWebsocketStore()
              websocketStore.disconnect()
            }
          }, {
            name: 'CompanyTagAll',
            meta: {
              title: 'companyTagAll',
              planName: [ 'free', 'basic', 'premium' ]
            },
            path: '/setting/companytag/all',
            component: CompanyTagAll,
            hidden: true,
            beforeEnter: () => {
              const websocketStore = useWebsocketStore()
              websocketStore.disconnect()
            }
          }
          // {
          //   name: 'CompanyDescription',
          //   meta: {
          //     title: 'companyDescription',
          //     // planName: ['free', 'basic', 'premium']
          //     planName: ['restricted']
          //   },
          //   path: '/setting/description',
          //   component: CompanyDescription
          // }
        ]}, {
        name: 'Profile',
        meta: {
          head: '個人資訊 - WarRoom - 谷林運算 GoodLinker',
          title: 'profile',
          planName: [ 'free', 'basic', 'premium' ],
          groupName: 'settings'
        },
        path: '/setting/authLog',
        component: InnerMenuTemplate,
        hidden: false,
        children: [
          {
            name: 'AuthLog',
            meta: {
              head: '登入紀錄- WarRoom - 谷林運算 GoodLinker',
              title: 'authLog',
              planName: [ 'free', 'basic', 'premium' ],
              groupName: 'settings'
            },
            path: '/setting/authLog',
            component: AuthLog,
            hidden: false,
            beforeEnter: () => {
              const websocketStore = useWebsocketStore()
              websocketStore.disconnect()
            }
          }
        ]
      }, {
        name: 'Log',
        meta: {
          head: 'API 紀錄 - WarRoom - 谷林運算 GoodLinker',
          title: 'log',
          planName: ['restricted']
        },
        path: '/setting/log',
        component: Log,
        hidden: true,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }, {
        name: 'Plan',
        meta: {
          head: '方案一覽 - WarRoom - 谷林運算 GoodLinker',
          title: 'Plan',
          planName: [ 'free', 'basic', 'premium' ]
        },
        path: '/plan',
        component: Plan,
        hidden: true,
        beforeEnter: () => {
          const websocketStore = useWebsocketStore()
          websocketStore.disconnect()
        }
      }
    // {
    //   name: 'Permission',
    //   meta: {
    //     title: 'permission',
    //     planName: ['restricted']
    //   },
    //   path: '/setting/permission',
    //   component: Permission
    // }
    ]
  }
]

export default viewRouter

import { defineStore } from 'pinia'
import { useUserStore } from '@/stores'
import * as Sentry from '@sentry/vue'

type IntervalId = ReturnType<typeof setInterval> | null

interface LogState {
  data: {
    userId: number | null
    email: string | null
    env: string
    path: string
    action: string
    info: string
  }[]
  intervalId: IntervalId,
}

interface LogData {
  path: string
  action: string
  info: string
}

export const useEventHandlerStore = defineStore('eventHandler', {
  state: (): LogState => ({
    data: [],
    intervalId: null
  }),
  persist: {
    enabled: true,
    strategies: [{ storage: sessionStorage, paths: ['data'] }]
  },
  actions: {
    async setLogData (logData: LogData) {
      const { path, action, info } = logData
      const userStore = useUserStore()
      const userId = userStore.user.id || null
      const username = userStore.user.name || ''
      const email = userStore.user.email || ''
      const env = import.meta.env.MODE
      // const eventId = Sentry.lastEventId()
      const locale = localStorage.getItem('language') || 'zh-TW'
      this.data.push({
        userId,
        email,
        env,
        path,
        action,
        info
      })
      // NOTE: User Feedback 可以給使用者回報使用，目前先關閉
      // const userFeedback = {
      //   name,
      //   email,
      //   message: `[${logData.path}] ${logData.action}`,
      //   associatedEventId: eventId
      // }
      // Sentry.captureFeedback(userFeedback)
      Sentry.setTag('page_locale', locale)
      Sentry.addBreadcrumb({
        message: logData.action,
        category: 'user_action',
        level: 'info',
        data: {
          path: logData.path,
          info: logData.info,
          userId,
          username,
          email
        }
      })
    }
  }
})
